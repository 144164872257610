export default [
    {
        path: '/publisher',
        meta: { title: 'MyMadrivo | publishers' },
        children: [
            {
                path: '/internal/publishers',
                name: 'publishers',
                component: () => import('@/views/pages/Publishers/Index.vue'),
                meta: { isInternal: true, requiresAuth: true, requiresAbility: 'isInternalUser' }
            },
            {
                path: '/internal/publishers/:id',
                name: 'publisherDetails',
                props: (route) => ({ id: route.params.id }),
                component: () => import('@/views/pages/Publishers/Details.vue'),
                meta: { isInternal: true, requiresAuth: true, requiresAbility: 'isInternalUser' }
            },
            {
                path: '/internal/publishers/change-request',
                name: 'publisherChangeApproval',
                component: () => import('@/views/pages/Publishers/ChangeApproval.vue'),
                meta: { isInternal: true, requiresAuth: true, requiresAbility: 'managePublisherTiers' }
            }
        ]
    }
];
