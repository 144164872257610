import { postData } from '@/api';
const state = {
    offer: null,
    relationships: null,
    isLoading: true
};

const getters = {
    offer: (state) => state.offer,
    relationships: (state) => state.relationships,
    isLoading: (state) => state.isLoading,
    offerData: (state) => state,
    trafficChannels: (state) => {
        const channelsString = state?.relationships?.airtable?.channels_allowed ?? null;
        return channelsString ? JSON.parse(channelsString) : [];
    },
    creatives: (state) => state?.relationships?.creatives ?? []
};

const mutations = {
    setOffer: (state, offer) => {
        state.offer = offer;
    },
    setOfferData: (state, offerData) => {
        state.offer = offerData.offer;
        state.relationships = offerData.relationships;
    },
    setRelationships: (state, relationships) => {
        state.relationships = relationships;
    },
    setIsLoading: (state, isLoading) => {
        state.isLoading = isLoading;
    },
    addChangeRequestToLog(state, newChangeRequest) {
        console.log('newChangeRequest', newChangeRequest);
        if (state && state.relationships && state.relationships.changeRequestLog) {
            state.relationships.changeRequestLog.push(newChangeRequest);
        }
    }
};

const actions = {
    // Define your actions here
    fetchOffer: async ({ state, commit }, offerId) => {
        try {
            if (!offerId || (state.offer && state.offer.network_offer_id == offerId)) {
                return;
            }
            commit('setIsLoading', true);

            const data = {
                relationships: ['airtable', 'creatives']
            };

            const response = await postData(`/api/offers/${offerId}`, data);

            commit('setOffer', response.offer);
            commit('setRelationships', response.relationships);
            commit('setIsLoading', false);
        } catch (error) {
            console.error('Error fetching offer:', error);
        }
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
