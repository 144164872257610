import * as Sentry from '@sentry/vue';

const state = {
    isInternal: false,
    user: { name: '', email: '', role: { abilities: [] }, two_factor_confirmed_at: null, two_factor_provider: null },
    isAuthenticated: false,
    advertiser_id: null
};

const getters = {
    isInternal: (state) => state.isInternal,
    isInternalUser: (state, getters) => {
        return getters.userCan('isInternalUser');
    },
    userCan: (state) => (abilityName) => {
        if (state.user.role && state.user.role.abilities.some((ability) => ability.name === '*')) {
            return true;
        }

        return state.user.role && state.user.role.abilities.some((ability) => ability.name === abilityName);
    },
    getUser: (state) => state.user,
    userName: (state) => state.user.name,
    userFirstName: (state) => state.user.name.split(' ')[0],
    isAuthenticated: (state) => state.isAuthenticated,
    getHasTwoFactor: (state) => state.user.two_factor_confirmed_at !== null,
    id: (state) => state.user.id
};

const actions = {
    setIsInternal({ commit }, isInternal) {
        commit('setIsInternal', isInternal);
    },
    logout({ commit }) {
        commit('setUser', { name: '', email: '', role: '', two_factor_confirmed_at: null, advertiser_id: null });
        commit('setAuthenticated', false);
        localStorage.removeItem('madrivoAuthToken');
    }
};

const mutations = {
    setIsInternal(state, isInternal) {
        state.isInternal = isInternal;
    },
    setUser(state, user) {
        state.user = user;
        Sentry.setUser({ id: user.id, email: user.email });
    },
    setAuthenticated(state, isAuthenticated) {
        state.isAuthenticated = isAuthenticated;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
