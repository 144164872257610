<script setup>
import { useLayout } from '@/layout/composables/layout';
import { computed } from 'vue';

const { layoutConfig } = useLayout();
const currentYear = computed(() => new Date().getFullYear());
</script>

<template>
    <div class="layout-footer">
        <span class="font-medium ml-2">&copy;{{ currentYear }} Madrivo. All rights reserved. </span>
    </div>
</template>
<style lang="scss" scoped>
@import '@/assets/styles/variables.scss';
.layout-footer {
    background: $primaryGradient;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    color: white;
    height: $footerHeight;
}
</style>
