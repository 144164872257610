export default {
    namespaced: true,
    state() {
        return {
            offers: [{ offerId: null, amount: null, hubspot_ticket_id: null, creative_request_id: null, error: null, files: [] }]
        };
    },
    mutations: {
        addFailedFile(state, failedFile) {
            state.failedFiles.push(failedFile);
        },
        addOffer(state) {
            state.offers.push({ offerId: null, amount: null, hubspot_ticket_id: null, creative_request_id: null, error: null, files: [] });
        },
        removeOffer(state, index) {
            state.offers.splice(index, 1);
        },
        updateOffer(state, { index, offer }) {
            state.offers[index] = offer;
        },
        addFileToOfferByRequestId(state, { creative_request_id, file }) {
            const offer = state.offers.find((offer) => offer.creative_request_id === creative_request_id);
            if (offer) {
                offer.files.push(file);
            }
        },
        resetOffers(state) {
            state.offers = [];
            state.offers.push({ offerId: null, amount: null, hubspot_ticket_id: null, creative_request_id: null, error: null, files: [] });
        }
    },
    actions: {
        // Your actions go here
    },
    getters: {
        failedFiles: (state) => state.failedFiles,
        offers: (state) => state.offers
    }
};
