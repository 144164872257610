export default [
    {
        path: '/404',

        children: [
            {
                path: '/404',
                component: () => import('@/views/pages/NotFound.vue'),
                meta: { title: 'MyMadrivo | 404' },
                name: '404'
            }
        ]
    },
    {
        path: '/403',

        children: [
            {
                path: '/403',
                component: () => import('@/views/pages/NotAuthorized.vue'),
                meta: { title: 'MyMadrivo | 403' },
                name: '403'
            }
        ]
    },
    {
        path: '/500',

        children: [
            {
                path: '/500',
                component: () => import('@/views/pages/InternalServerError.vue'),
                meta: { title: 'MyMadrivo | 500' },
                name: '500'
            }
        ]
    }
];
