export default [
    {
        path: '/newsletters',
        meta: { title: 'MyMadrivo | Newsletters' },
        children: [
            {
                path: '/internal/newsletters/manage/weeklyoffers',
                name: 'newsletters',
                component: () => import('@/views/pages/Newsletters/ManageWeeklyOffers.vue'),
                meta: { isInternal: true }
            }
        ]
    }
];
