export default [
    {
        path: '/wiki',

        meta: { title: 'MyMadrivo | Wiki', requiresWikiFetch: true },
        children: [
            {
                path: '', // default child route
                name: 'wikiHome',
                component: () => import('@/views/pages/Wiki/WikiHome.vue'),
                props: (route) => ({ })
            },
            {
                path: '/internal/wiki/create',
                name: 'wikiCreate',
                meta: { isInternal: true, requiresAuth: true, requiresAbility: 'canEditWiki' },
                component: () => import('@/views/pages/Wiki/WikiCreate.vue'),
                props: (route) => ({ })
            },
            {
                path: '/internal/wiki/edit/:articleId',
                name: 'wikiEdit',
                meta: { isInternal: true, requiresAuth: true, requiresAbility: 'canEditWiki' },
                component: () => import('@/views/pages/Wiki/WikiCreate.vue'),
                props: (route) => ({  articleId: route.params.articleId })
            },
            {
                path: ':articleId',
                name: 'wikiArticle',
                component: () => import('@/views/pages/Wiki/WikiArticle.vue'),
                props: (route) => ({ articleId: route.params.articleId })
            }
        ]
    }
];
