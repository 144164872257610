// Checks if the user is connected to the internal VPN
import { getData } from '@/api';

export default async function vpnGuard(to, from, next, store){
    const response = await getData('/api/auth/check-internal-ip').catch((error) => {
        next('403');
        return false;
    });

    if (!response.isInternal) {
        next('403');
        return false;
    }

    store.commit('auth/setIsInternal', true);
    return true;
}