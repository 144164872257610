export default [
    {
        path: '/internal/tools',
        meta: { title: 'MyMadrivo | Tools' },
        children: [
            {
                path: '/internal/tools/offerfocus',
                name: 'offerFocus',
                component: () => import('@/views/pages/tools/OfferFocus.vue'),
                meta: { isInternal: true, requiresAuth: true, requiresAbility: 'offerFocus' }
            },
            // TODO :: does this live here?
            {
                path: '/internal/tools/chat',
                name: 'AiChat',
                component: () => import('@/views/pages/tools/AiChat.vue'),
                meta: { isInternal: true, requiresAuth: true, requiresAbility: 'aiChat' }
            }
        ]
    }
];
