const state = {
    search: '',
    profile: {},
    offers: {},
    isLoadingOffers: true
};

const getters = {
    search: (state) => state.search,
    profile: (state) => state.profile,
    profileAccountStatus: (state) => state?.profile?.account_status,
    categoriesOfVisibleOffers: (state) => {
        return state.offers?.categoriesOfVisibleOffers || [];
    },
    isLoadingOffers: (state) => state.isLoadingOffers
};

const actions = {
    setSearch({ commit }, search) {
        commit('setSearch', search);
    },
    clearSearch({ commit }) {
        commit('setSearch', '');
    },
    setProfile({ commit }, profile) {
        commit('setProfile', profile);
    },
    setOffers({ commit }, offers) {
        commit('setOffers', offers);
    },
    setIsLoadingOffers({ commit }, isLoading) {
        commit('setIsLoadingOffers', isLoading);
    }
};

const mutations = {
    setSearch(state, search) {
        state.search = search;
    },
    setProfile(state, profile) {
        state.profile = profile;
    },
    setOffers(state, offers) {
        state.offers = offers;
    },
    setIsLoadingOffers(state, isLoading) {
        state.isLoadingOffers = isLoading;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
