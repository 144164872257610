import { getData, postDataStreamedResponse } from '@/api';

const state = {
    messages: [],
    sendingMessage: false,
    tools: [],
    loadingTools: true,
    currentTool: 0,
    conversations: [],
    loadingConversations: true,
    loadingConversation: false,
    currentConversationId: null
};

const getters = {
    tools: (state) => state.tools,
    selectedTool: (state) => state.tools[state.currentTool],
    selectedToolId: (state) => state.tools[state.currentTool]?.id ?? null,
    loadingTools: (state) => state.loadingTools,
    conversations: (state) => state.conversations,
    selectedConversation: (state) => state.conversations.find((conversation) => conversation.id === state.currentConversationId),
    selectedConversationId: (state) => state.currentConversationId,
    loadingConversations: (state) => state.loadingConversations,
    loadingConversation: (state) => state.loadingConversation,
    messages: (state) => state.messages,
    sendingMessage: (state) => state.sendingMessage
};

const actions = {
    addMessage({ commit }, message) {
        commit('newMessage', message);
    },
    clearMessages({ commit }) {
        commit('clearAllMessages');
    },
    async fetchTools({ commit }) {
        commit('setLoadingTools', true);
        const toolData = await getData('/api/ai/tools');
        console.log('Tools:', toolData);
        commit('setLoadingTools', false);
        const filteredData = toolData.filter((tool) => tool.is_active);
        commit('setTools', filteredData);
    },
    async fetchConversations({ commit }) {
        commit('setLoadingConversations', true);
        const conversationData = await getData('/api/ai/conversations');
        console.log('Conversations:', conversationData);
        commit('setConversations', conversationData);
        commit('setLoadingConversations', false);
    },
    async setCurrentConversation({ commit, state, dispatch }, conversationId) {
        commit('setCurrentConversationId', conversationId);
        const conversation = state.conversations.find((convo) => convo.id === conversationId);
        if (conversation) {
            commit('setCurrentTool', conversation.tool_id);
            await dispatch('fetchMessages', conversationId);
        }
    },
    async fetchMessages({ commit }, conversationId) {
        commit('setLoadingConversation', true);
        const messageData = await getData(`/api/ai/conversations/${conversationId}/messages`);
        console.log('Messages:', messageData);
        commit('setMessages', messageData);
        commit('setLoadingConversation', false);
    },
    async sendMessage({ commit, state, dispatch }, { message, selectedFiles }) {
        if (!message) {
            return false;
        }

        const conversationId = state.currentConversationId;
        const toolId = state.tools[state.currentTool]?.id ?? false;

        if (!toolId) {
            return false;
        }

        const fileIds = selectedFiles?.map((file) => file.id);

        commit('setSendingMessage', true);
        commit('newMessage', { content: message, type: 'user', vector_files: selectedFiles });
        const assistantMessageIndex = 0;
        commit('newMessage', { content: '', type: 'system', loading: true });

        const messageData = await postDataStreamedResponse(`/api/ai/message`, { tool_id: toolId, conversation_id: conversationId, message: message, file_ids: fileIds }, async function (partialResponse) {
            if (partialResponse.event === 'thread.message.created') {
                const lastMessage = state.messages[0];
                if (lastMessage.content != '') {
                    console.log('New message:', partialResponse.response);
                    console.log('Last message:', lastMessage);
                    commit('newMessage', { content: '', type: 'system', loading: true });
                }
            }

            if (partialResponse.event === 'thread.run.failed') {
                console.error('Failed to run thread:', partialResponse.response);
                commit('updateMessage', {
                    index: assistantMessageIndex,
                    content: 'Sorry, I am unable to process your request at the moment. Please try again later.',
                    loading: false,
                    error: true
                });
            }

            if (partialResponse.event === 'thread.message.delta') {
                commit('updateMessage', {
                    index: assistantMessageIndex,
                    content: partialResponse.response ?? '',
                    loading: false
                });
            }

            if (partialResponse.event === 'thread.conversation.created') {
                let conversationId = partialResponse.response;

                commit('setCurrentConversationId', conversationId);

                const preview = message.length > 50 ? message.substring(0, 50) + '...' : message;
                commit('addConversation', { id: conversationId, tool_id: toolId, created_at: new Date().toISOString(), preview: preview });

                dispatch('updateConversationLabel', { conversationId });
            }

            if (partialResponse.event === 'thread.message.completed') {
                // Override message and format
                commit('overrideMessage', {
                    index: assistantMessageIndex,
                    content: partialResponse.response ?? '',
                    loading: false
                });
            }
        }).finally(() => {
            console.log('Message sent');
            commit('setSendingMessage', false);
        });
    },
    async updateConversationLabel({ commit }, { conversationId }) {
        let attempts = 0;
        let conversation = null;

        while (attempts < 5) {
            conversation = await getData(`/api/ai/conversations/${conversationId}`);

            if (conversation.label) {
                break;
            }

            attempts++;
            await new Promise((resolve) => setTimeout(resolve, 5000));
        }

        const label = conversation.label;
        commit('updateConversation', { conversationId, conversation: { id: conversationId, tool_id: conversation.tool_id, created_at: conversation.created_at, label } });
    }
};

const mutations = {
    newMessage: (state, message) => state.messages.unshift(message),
    updateMessage: (state, { index, content, loading }) => {
        if (state.messages[index]) {
            state.messages[index].content += content;
            state.messages[index].loading = loading;
        }
    },
    overrideMessage: (state, { index, content, loading }) => {
        if (state.messages[index]) {
            state.messages[index].content = content;
            state.messages[index].loading = loading;
        }
    },
    setSendingMessage: (state, sending) => (state.sendingMessage = sending),
    clearAllMessages: (state) => (state.messages = []),
    startNewConversation: (state) => {
        state.messages = [];
        state.currentConversationId = null;
    },
    setMessages: (state, messages) => (state.messages = messages),
    setTools: (state, tools) => (state.tools = tools),
    setConversations: (state, conversations) => (state.conversations = conversations),
    addConversation: (state, conversation) => state.conversations.push(conversation),
    updateConversation: (state, { conversationId, conversation }) => {
        const index = state.conversations.findIndex((convo) => convo.id === conversationId);
        state.conversations[index] = conversation;
    },
    removeConversation: (state, conversationId) => {
        state.conversations = state.conversations.filter((convo) => convo.id !== conversationId);
    },
    setCurrentTool: (state, toolId) => {
        state.currentTool = state.tools.findIndex((tool) => tool.id === toolId);
    },
    setLoadingTools: (state, loading) => (state.loadingTools = loading),
    setLoadingConversations: (state, loading) => (state.loadingConversations = loading),
    setLoadingConversation: (state, loading) => (state.loadingConversation = loading),
    setCurrentConversationId: (state, conversationId) => (state.currentConversationId = conversationId)
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
