export default [
    {
        path: '/questionnaire',
        meta: { title: 'MyMadrivo | Questionnaires' },
        children: [
            {
                path: '/questionnaire/:uuid',
                meta: { isInternal: false },
                name: 'questionnaire',
                component: () => import('@/views/pages/Questionnaires/Index.vue'),
                props: (route) => ({ uuid: route.params.uuid })
            },
            {
                path: '/internal/questionnaire/:uuid',
                meta: { isInternal: true },
                component: () => import('@/views/pages/Questionnaires/Index.vue'),
                props: (route) => ({ uuid: route.params.uuid })
            },
            {
                path: '/internal/questionnaire/setup/:uuid',
                name: 'questionnaireSetup',
                component: () => import('@/views/pages/Questionnaires/Index.vue'),
                props: (route) => ({ uuid: route.params.uuid }),
                meta: { isInternal: true }
            },
            {
                path: '/questionnaire/thanks',
                name: 'questionnaireThanks',
                component: () => import('@/views/pages/Questionnaires/QuestionnaireThankYou.vue')
            },
            {
                path: '/internal/questionnaire/thanks',
                meta: { isInternal: true },
                component: () => import('@/views/pages/Questionnaires/QuestionnaireThankYou.vue')
            }
        ]
    }
];
