import { getData } from '@/api';
export default {
    namespaced: true,
    state() {
        return {
            articles: [], // Each article: { id, title, body, isActive }
            loading: false,
            hasLoaded: false,
            userCanEdit: false
        };
    },
    mutations: {
        addArticle(state, article) {
            state.articles.push(article);
        },
        updateArticle(state, updatedArticle) {
            const index = state.articles.findIndex((article) => article.id == updatedArticle.id);
            if (index !== -1) {
                state.articles[index] = updatedArticle;
            }
        },
        deleteArticle(state, articleId) {
            state.articles = state.articles.filter((article) => article.id !== articleId);
        },
        setLoading(state, status) {
            state.loading = status;
        },
        setArticles(state, articles) {
            state.articles = articles;
        },
        setHasLoaded(state, status) {
            state.hasLoaded = status;
        },
        setUserCanEdit(state, canEdit) {
            state.userCanEdit = canEdit;
        }
    },
    actions: {
        async fetchArticles({ commit }) {
            commit('setLoading', true);
            try {
                const data = await getData(`/api/wiki/articles`);
                // Check if data is an array
                if (!Array.isArray(data)) {
                    console.error('Error: API did not return an array. Received:', data);
                    commit('setLoading', false);
                    return false;
                }

                commit('setArticles', data);
            } catch (error) {
                console.error('Error fetching articles:', error);
                commit('setLoading', false);
                return false;
            }
            commit('setLoading', false);
            commit('setHasLoaded', true);
            return true;
        }
    },
    getters: {
        allArticles: (state) => state.articles,
        getArticleById: (state) => (id) => {
            return state.articles.find((article) => article.id == id);
        },
        isLoading: (state) => state.loading,
        hasLoaded: (state) => state.hasLoaded,
        userCanEdit: (state) => state.userCanEdit
    }
};
