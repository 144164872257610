export default [
    {
        path: '/creative',

        meta: { title: 'MyMadrivo | Creatives' },
        children: [
            {
                path: '/internal/creative/request',
                name: 'creativeRequest',
                component: () => import('@/views/pages/CreativeApprovals/CreativeApproval.vue'),
                meta: { isInternal: true }
            },
            {
                path: '/creative/thanks',
                name: 'creativeThanks',
                component: () => import('@/views/pages/CreativeApprovals/CreativeThanks.vue')
            },
            {
                path: '/creative/request',
                component: () => import('@/views/pages/CreativeApprovals/CreativeApproval.vue'),
                meta: { isInternal: false }
            }
        ]
    }
];
