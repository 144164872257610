import { getData } from '@/api';

const state = {
    questionnaire: {},
    meta: {}
};

const getters = {
    getQuestionnaire: (state) => {
        return state.questionnaire;
    },
    getHubspotObjectId: (state) => state.questionnaire?.hubspot_object_id || null,
    getHubspotObjectType: (state) => state.questionnaire?.hubspot_object_type || null,
    getQuestionnaireId: (state) => state.questionnaire?.id || null,
    getQuestionnaireTypeId: (state) => state.questionnaire?.questionnaire_type_id || null,
    getStatus: (state) => state.questionnaire?.status || null,
    getUuid: (state) => state.questionnaire?.uuid || null,
    getFiles: (state) => state.questionnaire?.files || null,
    getIsSubmitted: (state) => state.questionnaire?.response?.questionnaire_status === 'submitted' || false,
    getMeta: (state) => state.meta
};

const actions = {
    async initializeQuestionnaire({ dispatch, commit }, uuid) {
        if (!uuid) throw new Error('Questionnaire UUID is required.');

        try {
            await dispatch('form/resetForm', null, { root: true });
            await dispatch('fetchQuestionnaireByUUID', uuid);
            await dispatch('fetchQuestionnaireFields');
            await dispatch('fetchQuestionnaireMeta');
            commit('form/setType', 'questionnaire', { root: true });
            await dispatch('setFieldValuesFromResponse');
            await dispatch('setQuestionnaireFiles');
        } catch (error) {
            // Handle errors
            console.error(error);
            throw error;
        }
    },

    async fetchQuestionnaireByUUID({ commit }, uuid) {
        const response = await getData(`/api/questionnaire/${uuid}`);
        console.log('Questionnaire response:', response);
        commit('setQuestionnaire', response);
    },

    async fetchQuestionnaireMeta({ commit, state, dispatch, rootGetters }, typeId = null) {
        try {
            typeId = typeId ? typeId : state.questionnaire.questionnaire_type_id;
            const metaData = await getData(`/api/questionnaire/${typeId}/meta`);
            await commit('setMeta', metaData);
        } catch (error) {
            console.error('Error fetching questionnaire meta:', error);
            return false;
        }
    },

    async fetchQuestionnaireFields({ commit, state, dispatch, rootGetters }, typeId = null) {
        try {
            typeId = typeId ? typeId : state.questionnaire.questionnaire_type_id;
            const questionnaireResponse = state.questionnaire.response || {};
            if (!typeId) {
                throw new Error('Questionnaire type ID not found in state.');
            }
            const response = await getData(`/api/questionnaire/type/${typeId}/fields`);

            const formData = response.map((field) => {
                field.id = field.key;

                if (field.default_value && field.default_value?.startsWith('store.getters')) {
                    // Extract the module and getter name from the string
                    const getterPath = field.default_value.split('[')[1].split(']')[0].replace(/'/g, '').replace(/"/g, '').trim();
                    field.value = rootGetters[getterPath];
                    console.log('Field value from getter:', field.value);
                } else if (field.default_value) {
                    field.value = field.default_value;
                }

                return field;
            });

            let maxGroup = formData.reduce((max, field) => {
                return field.field_group > max ? field.field_group : max;
            }, 0);

            let repeatedGroups = [];
            await commit('form/setFields', formData, { root: true });

            // Format repeated fields
            formData.forEach((field) => {
                if (field.validation_rules?.includes('repeatable')) {
                    const minRule = field.validation_rules?.find((r) => r.startsWith('min:'));
                    if (field.field_group === undefined || field.field_group === null) {
                        field.field_group = maxGroup + 1;
                        maxGroup++;
                    }
                    const roundedGroup = Math.round(field.field_group);
                    let fields;

                    if (!repeatedGroups.includes(roundedGroup)) {
                        repeatedGroups.push(roundedGroup);
                        fields = formData
                            .filter((f) => f.field_group === roundedGroup)
                            .map((field) => {
                                field.iteration = 0;
                                field.id = field.id + '.0';
                                return field;
                            });
                    }

                    if (!fields) {
                        return;
                    }
                    // check for existing responses and add additional fields if necessary
                    const responseField = questionnaireResponse[field.key];
                    if (responseField && Array.isArray(responseField)) {
                        let repeatCount = responseField.length - 1;
                        dispatch('form/duplicateGroup', { fields, repeatCount }, { root: true });
                        return;
                    }

                    if (minRule) {
                        const minCount = parseInt(minRule.split(':')[1], 10);
                        let repeatCount = minCount - 1;

                        dispatch('form/duplicateGroup', { fields, repeatCount }, { root: true });
                    }
                }
            });
            return true;
        } catch (error) {
            console.error('Error fetching questionnaire fields by type ID:', error);
            return false;
        }
    },

    async setQuestionnaireFiles({ commit, state, rootState }) {
        const files = state.questionnaire.files;
        for (const file of files) {
            if (!file.uuid) {
                continue;
            }
            const field = rootState.form.formData.find((field) => Array.isArray(field.value) && field.value.includes(file.uuid));
            if (!field) {
                continue;
            }
            const fieldId = field.id;

            const formattedFile = { name: file.name, size: file.filesize, uuid: file.uuid, status: 'Uploaded', file: '[object File]', fieldId: fieldId, preview: false, errors: [], loading: false };

            commit('form/addSelectedFiles', [formattedFile], { root: true });
        }
    },

    async setFieldValuesFromResponse({ commit, state, dispatch, rootState }) {
        const questionnaireResponse = state.questionnaire?.response;
        if (questionnaireResponse) {
            try {
                if (questionnaireResponse.questionnaire_status === 'submitted') {
                    commit('form/setDisabled', true, { root: true });
                } else {
                    commit('form/setDisabled', false, { root: true });
                }

                Object.keys(questionnaireResponse).forEach((fieldId) => {
                    const fieldValue = questionnaireResponse[fieldId];

                    if (fieldValue === null || fieldValue === undefined) {
                        return;
                    }

                    // Handle Repeatable Fields
                    if (Array.isArray(fieldValue)) {
                        const field = rootState.form.formData.find((f) => f.id === `${fieldId}.0`);

                        if (!field) {
                            commit('form/setFieldValue', { id: fieldId, value: fieldValue }, { root: true });
                            return;
                        }

                        fieldValue.forEach((item, index) => {
                            const arrayFieldId = `${fieldId}.${index}`;
                            commit('form/setFieldValue', { id: arrayFieldId, value: item }, { root: true });

                            if (fieldId.includes('country_code') && item) {
                                dispatch('form/fetchRegionsForCountry', item, { root: true });
                            }
                        });
                    } else {
                        // For non-array fields, commit as usual
                        commit('form/setFieldValue', { id: fieldId, value: fieldValue }, { root: true });

                        if (fieldId.includes('country_code') && fieldValue) {
                            dispatch('form/fetchRegionsForCountry', fieldValue, { root: true });
                        }
                    }
                });
            } catch (error) {
                console.error('Error parsing questionnaire response:', error);
            }
        }
    },
    async overrideResponse({ commit, state, dispatch, rootState }, newResponse) {
        // Commit the new response to the state
        commit('setQuestionnaireResponse', newResponse);

        await dispatch('setFieldValuesFromResponse');
    }
};

const mutations = {
    setQuestionnaire(state, questionnaire) {
        state.questionnaire = questionnaire;
    },
    setQuestionnaireTypeFields(state, fields) {
        state.questionnaireTypeFields = fields;
    },
    setQuestionnaireResponse(state, response) {
        state.questionnaire.response = response;
    },
    setMeta(state, meta) {
        state.meta = meta;
    },
    setStatus(state, status) {
        state.questionnaire.status = status;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
