<script setup>
import { computed, watch, ref, provide, onMounted, onUnmounted } from 'vue';
import AppTopbar from './AppTopbar.vue';
import AppFooter from './AppFooter.vue';
import AppSidebar from './AppSidebar.vue';
import AppConfig from './AppConfig.vue';
import { useLayout } from '@/layout/composables/layout';
import { useStore } from 'vuex';
import { useToast } from 'primevue/usetoast';

const toast = useToast();
const store = useStore();

const isAuthenticated = computed(() => {
    return store.getters['auth/isAuthenticated'];
});

// TODO :: Find all instances of toast.add and replace with showToast using inject.
const showToast = ({ severity, detail, life }) => {
    toast.add({
        severity,
        detail,
        life: life || 3000
    });
};
provide('showToast', showToast);

const { layoutConfig, layoutState, isSidebarActive, isSubSidebarActive, isMobileScreen } = useLayout();

const outsideClickListener = ref(null);
const hasSubSidebar = ref(false);

watch(isSidebarActive, (newVal) => {
    if (newVal && isMobileScreen.value) {
        bindOutsideClickListener();
    } else {
        unbindOutsideClickListener();
    }
});

const containerClass = computed(() => {
    return {
        'layout-theme-light': layoutConfig.darkTheme.value === 'light',
        'layout-theme-dark': layoutConfig.darkTheme.value === 'dark',
        'layout-overlay': layoutConfig.menuMode.value === 'overlay',
        'layout-static': layoutConfig.menuMode.value === 'static',
        'layout-static-inactive': layoutState.staticMenuDesktopInactive.value && layoutConfig.menuMode.value === 'static',
        'layout-overlay-active': layoutState.overlayMenuActive.value,
        'layout-mobile-active': layoutState.staticMenuMobileActive.value,
        'p-input-filled': layoutConfig.inputStyle.value === 'filled',
        'p-ripple-disabled': !layoutConfig.ripple.value,
        'sub-sidebar-active': isSubSidebarActive.value,
        'has-sub-sidebar': hasSubSidebar.value,
        'desktop-sidebar-active': !layoutState.staticMenuDesktopInactive.value
    };
});

const bindOutsideClickListener = () => {
    if (!outsideClickListener.value) {
        outsideClickListener.value = (event) => {
            if (isOutsideClicked(event)) {
                layoutState.overlayMenuActive.value = false;
                layoutState.staticMenuMobileActive.value = false;
                layoutState.menuHoverActive.value = false;
            }
        };
        document.addEventListener('click', outsideClickListener.value);
    }
};

const unbindOutsideClickListener = () => {
    if (outsideClickListener.value) {
        document.removeEventListener('click', outsideClickListener.value);
        outsideClickListener.value = null;
    }
};

const isOutsideClicked = (event) => {
    const sidebarEl = document.querySelector('.layout-sidebar');
    const topbarEl = document.querySelector('.layout-topbar-button');

    return !(sidebarEl.isSameNode(event.target) || sidebarEl.contains(event.target) || topbarEl.isSameNode(event.target) || topbarEl.contains(event.target));
};

// todo :: move this to the sidebar onMounted
const checkHasSubSidebar = () => {
    const subSidebarEl = document.querySelector('.sub-sidebar');
    hasSubSidebar.value = !!subSidebarEl;
};
onMounted(() => {
    checkHasSubSidebar();
    const observer = new MutationObserver(checkHasSubSidebar);
    observer.observe(document.body, { childList: true, subtree: true });

    onUnmounted(() => {
        observer.disconnect();
    });
});
</script>

<template>
    <div class="layout-wrapper" :class="containerClass">
        <Toast />
        <app-topbar></app-topbar>
        <div v-if="isAuthenticated" class="layout-sidebar">
            <app-sidebar></app-sidebar>
        </div>
        <div class="layout-main-container">
            <div class="layout-main">
                <router-view></router-view>
            </div>
        </div>

        <div class="layout-mask"></div>
        <app-footer></app-footer>
    </div>
</template>

<style lang="scss" scoped>
@import '@/assets/styles/variables.scss';
.layout-main {
    display: flex;

    & > div {
        flex-grow: 1;
    }
}

.layout-main-container {
    min-height: calc(100vh - $footerHeight);
}

.has-sub-sidebar {
    .layout-main-container {
        margin-left: 40px !important;
    }
}

.has-sub-sidebar.desktop-sidebar-active {
    .layout-main-container {
        margin-left: $sidebarWidth + 20px !important;
    }
}

.sub-sidebar-active {
    & .layout-main-container {
        margin-left: $subSidebarWidth !important;
    }
}

.sub-sidebar-active.desktop-sidebar-active {
    & .layout-main-container {
        margin-left: calc($subSidebarWidth + $sidebarWidth) !important;
    }
}

@media screen and (max-width: $breakpointMD) {
    .sub-sidebar-active {
        & .layout-mask {
            display: block;
        }

        & .layout-main-container {
            margin-left: 0 !important;
        }
    }
}
</style>
