// Checks if the user is logged in and has the correct permissions.
import { refreshUser } from '@/api';

export default async function authGuard(to, from, next, store) {
    const requiresAuth = to.matched.some((route) => route.meta.requiresAuth);
    const requiresAbility = to.matched.some((route) => route.meta.requiresAbility);
    let didRefreshUser = false;

    // if the user is already Authenticated, no need to refresh the user
    if (store.getters['auth/isAuthenticated']) {
        didRefreshUser = true;
    }

    // If a token exists in local storage but not in the store, refresh the users data
    const existingToken = localStorage.getItem('madrivoAuthToken');
    if (existingToken && !store.getters['auth/isAuthenticated']) {
        didRefreshUser = await refreshUser();
    }

    if (requiresAuth && !didRefreshUser) {

        next('/login');
        return false;
    }

    if (requiresAuth && didRefreshUser && requiresAbility) {
        const ability = to.meta.requiresAbility;

        if (!store.getters['auth/userCan'](ability)) {
            next('/403');
            return false;
        }
    }

    return true;
}
